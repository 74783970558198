import Vue from "vue";
import VueRouter from "vue-router";
import { getAccessTokenFromCookie, isUserLoggedIn } from "@/auth/utils";
import dashboard from "./routes/dashboard";
import invite from "./routes/admin/invite";
import customVedio from "./routes/admin/customVedio";
import admin from "./routes/admin";
import pages from "./routes/pages";
import amRegister from "./routes/register";
import settings from "./routes/settings";
import store from "@/store";
import eventCount from "./routes/analytics/userSearch"
import marketplace from "./routes/marketplace";
import keywordManager from "./routes/keyword-manager/index";
import marketManager from "./routes/market-manager";
import news from "./routes/news";
import topCharts from "./routes/top-charts";
import topCollections from "./routes/top-collections";
import ability from "@/libs/acl/ability";
import subscription from "./routes/subscription";
import adminSubscription from "./routes/admin/subscription";
import myApps from "./routes/myApps";
import invoices from "./routes/admin/invoices";
import coupon from "./routes/admin/coupon";
import discord from "./routes/discord";
import support from "./routes/support";
import listingManager from "./routes/listing-manager/index";
import keywordTracking from "./routes/keyword-tracking";


Vue.use(VueRouter);
let name;
const data = getAccessTokenFromCookie();
if (data) {
  name =
  data[2].role === "user"
      ? "dashboard"
      : data[2].role === "admin"
      ? "admin-dash"
      : "error-404";
}else {
  name = 'auth-login'
}
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name } },
    ...dashboard,
    // ...listingManager,
    // ...keywordTracking,
    ...invite,
    ...admin,
    ...amRegister,
    ...marketManager,
    ...keywordManager,
    ...topCharts,
    ...pages,
    ...settings,
    ...customVedio,
    ...eventCount,
    ...marketplace,
    ...news,
    ...topCollections,
    ...subscription,
    ...adminSubscription,
    ...myApps,
    ...invoices,
    ...coupon,
    ...discord,
    ...support,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const data = getAccessTokenFromCookie();
  if (
    !data &&
    (to.path === "/login" ||
    to.path === "/email-verification" ||
    to.path === "/reset-password" ||
    to.path == "/error-404" ||
    to.path === "/success-register" ||
    to.path === "/register" ||
    to.path === "/signup/verify-email")
  ) {
    return next(true);
  }
  if(!data && to.path === "/email-invite") {
    return next('/register');
  }
  const isLoggedIn = isUserLoggedIn();
  if (isLoggedIn) {
    ability.update([
      {
        action: "manage",
        subject: "all",
      },
    ]);
    return next();
  }
  // if (!store.state.sent && !to.query.token) return next({ name: "auth-login" });
  else return next('/login');
});

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  const appLoading = document.querySelector(".loading-container");
  if (appLoading) {
    appLoading.remove();
  }
});

export default router;
