import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionApi from "@vue/composition-api";

import Vuesax from "vuesax";
import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

Vue.prototype.$appVersion = "4.0.3";

Vue.prototype.$appVersionDate = "29-11-2023";

// Packages
// require lib
import VueCodemirror from "vue-codemirror";

// require styles
import "codemirror/lib/codemirror.css";

// require more codemirror resource...

// you can set default global options and events when use
Vue.use(VueCodemirror, {
  // options: { theme: 'dracula' },
  //   events: ['scroll', ...]
});
// Vuesax

import "vuesax/dist/vuesax.css";

// Global Components
import "./global-components";

// 3rd party plugins
// import "@axios";
import "@/libs/acl";
// import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
// import "@/libs/sweet-alerts";
// import "@/libs/vue-select";
// import "@/libs/tour";

// Vuesax styles
Vue.use(Vuesax, {
  // options here
});

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionApi);

//Vue-ApexCharts
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (component) => component(App),
}).$mount("#app");
